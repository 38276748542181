import React, { FormEvent, FunctionComponent } from 'react';
import classNames from 'classnames';
import { TFormFieldType, TInputType } from 'src/utils/types';
import styles from './InputField.module.scss';
import FormLabel from '../FormLabel/FormLabel';

interface IInputField {
  name: string;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  min?: number;
  max?: number;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  label?: string;
  type?: TInputType | TFormFieldType;
  onChange?: (e: FormEvent<any>) => void;
}

const InputField: FunctionComponent<IInputField> = ({
  className,
  name,
  label,
  type = 'text',
  placeholder,
  disabled = false,
  required = true,
  min,
  max,
  sm = false,
  md = false,
  lg = false,
  ...props
}) => {
  let inputField;

  const defaultProps = {
    ['data-test']: 'component-input-field',
    className: classNames([
      styles['input-field'],
      {
        [styles['input-field--sm']]: sm,
        [styles['input-field--md']]: md,
        [styles['input-field--lg']]: lg,
      },
      className,
    ]),
    type,
    name,
    placeholder,
    disabled,
    required,
    'aria-label': `Input for ${name}`,
    minLength: min,
    maxLength: max,
    ...props,
  };

  switch (type) {
    case 'text':
    case 'email':
    case 'password':
    case 'date':
    default:
      inputField = <input {...defaultProps} />;
      break;
    case 'number':
      inputField = <input {...defaultProps} />;
      break;
  }
  return (
    <>
      {label && <FormLabel text={label} name={name} size={'md'} required={required} />}
      {inputField}
    </>
  );
};

export default InputField;
