import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TOnClick } from 'src/utils/types';

import styles from './SocialItem.module.scss';
import { IconName } from '@fortawesome/fontawesome-common-types';

interface ISocialItemProps {
  name: IconName;
  link: string;
  onClick: TOnClick;
  className?: string;
}

const SocialItem: FunctionComponent<Partial<ISocialItemProps>> = (
  {
    name,
    link,
    onClick,
    className,
  },
) => {
  let icon: IconName;

  switch (name) {
    case 'facebook':
      icon = 'facebook-square';
      break;
    case 'linkedin':
      icon = 'linkedin';
      break;

    case 'twitter':
      icon = 'twitter-square';
      break;

    case 'youtube':
      icon = 'youtube-square';
      break;

    default:
      icon = name as IconName;
  }

  return (
    <li
      className={classNames(
        styles['social-item'],
        className,
        styles['social-item--theme-gradient-brand'],
      )}
      data-test="component-social-item"
    >
      <a
        data-test="social-item-link"
        className={styles['social-item__link']}
        href={link}
        aria-label={name}
        target="_blank"
        onClick={onClick}
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon data-test="social-item-icon" icon={['fab', icon]}/>
      </a>
    </li>
  );
};

export default SocialItem;
