import React, { FunctionComponent } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import classNames from 'classnames';

import styles from './SocialMenu.module.scss';
import SocialItem from '../../atoms/SocialItem/SocialItem';
import { Wordpress__Acf_OptionsConnection } from 'src/utils/types/graphql';

interface PureSocialMenuInterface extends SocialMenuInterface {
  allWordpressAcfOptions: Wordpress__Acf_OptionsConnection;
}

interface SocialMenuInterface {
  className?: string;
}

export const PureSocialMenu: FunctionComponent<PureSocialMenuInterface> = ({
  className,
  allWordpressAcfOptions,
}) => {
  const { options } = allWordpressAcfOptions.edges[0].node;
  const { facebook, twitter, instagram, linkedin, youtube } = options;

  return (
    <nav
      data-test="component-social-menu"
      className={classNames(styles['social-menu'], className)}
    >
      <ul className={styles['social-menu__list']}>
        {facebook && (
          <SocialItem
            className={styles['social-menu__item']}
            name="facebook"
            link={facebook}
          />
        )}

        {twitter && (
          <SocialItem
            className={styles['social-menu__item']}
            name="twitter"
            link={twitter}
          />
        )}

        {instagram && (
          <SocialItem
            className={styles['social-menu__item']}
            name="instagram"
            link={instagram}
          />
        )}

        {linkedin && (
          <SocialItem
            className={styles['social-menu__item']}
            name="linkedin"
            link={linkedin}
          />
        )}

        {youtube && (
          <SocialItem
            className={styles['social-menu__item']}
            name="youtube"
            link={youtube}
          />
        )}
      </ul>
    </nav>
  );
};

const SocialMenu: FunctionComponent<SocialMenuInterface> = ({ className }) => (
  <StaticQuery
    data-test="component-primary-menu-query"
    query={menuQuery}
    render={props => <PureSocialMenu {...props} className={className} />}
  />
);

export default SocialMenu;

const menuQuery = graphql`
  {
    allWordpressAcfOptions {
      edges {
        node {
          id
          options {
            facebook
            twitter
            instagram
            linkedin
            youtube
          }
        }
      }
    }
  }
`;
