import { graphql, Link, StaticQuery } from 'gatsby';
import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import Container from 'src/components/grid/Container/Container';
import Flex from 'src/components/grid/Flex/Flex';
import Row from 'src/components/grid/Row/Row';
import Brand from 'src/components/molecules/Brand/Brand';
import FooterMenu from 'src/components/molecules/FooterMenu/FooterMenu';
import SocialMenu from 'src/components/molecules/SocialMenu/SocialMenu';
import Heading from 'src/components/shared/Heading/Heading';
import styles from './PrimaryFooter.module.scss';

interface PrimaryFooterInterface {
  company?: string;
}

const PrimaryFooter: FunctionComponent<PrimaryFooterInterface> = ({
  company = '&copy; PixelTwist a trading name of JPPdesigns Webdesign & Development',
}) => {
  return (
    <footer
      data-test="component-primary-footer"
      className={styles['primary-footer']}
    >
      <section className={styles.primaryFooterTop}>
        <Container fluid className={classNames(styles.primaryFooterContainer)}>
          <Row>
            <Flex
              colLg={3}
              colMd={2}
              className={classNames(['mb-2', 'order-0', 'order-md-1'])}
            >
              <Link to={`/`}>
                <Brand
                  className={styles.primaryFooterBrand}
                  type={'symbol'}
                  theme={'tint-alpha'}
                />
              </Link>
            </Flex>

            <Flex colLg={3} colMd={4} colSm={6} className="order-1">
              <Heading priority={5} className={styles.primaryFooterHeading5}>
                Services
              </Heading>

              <ul className={styles.primaryFooterList}>
                <li className={styles.primaryFooterItem}>
                  <Link to={`/services/web-design-and-development`}>
                    Web Design & Development
                  </Link>
                </li>
                <li className={styles.primaryFooterItem}>
                  <Link to={`/services/graphic-design`}>Graphic Design</Link>
                </li>

                <li className={styles.primaryFooterItem}>
                  <Link to={`/services/seo`}>S.E.O</Link>
                </li>

                <li className={styles.primaryFooterItem}>
                  <Link to={`/services/strategy`}>Strategy</Link>
                </li>

                <li className={styles.primaryFooterItem}>
                  <Link to={`/services/hosting`}>Hosting</Link>
                </li>
              </ul>
            </Flex>

            <Flex
              colLg={3}
              colMd={4}
              colSm={6}
              className="order-1 display-none display-sm-block"
            >
              <Heading priority={5} className={styles.primaryFooterHeading5}>
                Recent Projects
              </Heading>

              <ul className={styles.primaryFooterList}>
                <StaticQuery
                  query={projectQuery}
                  render={props => {
                    const { allWordpressWpProjects } = props;
                    return allWordpressWpProjects.edges.map((edge: any) => {
                      const { node: project } = edge;
                      return (
                        <li
                          key={project.id}
                          className={styles.primaryFooterItem}
                        >
                          <Link to={`/project/${project.slug}`}>
                            {project.title}
                          </Link>
                        </li>
                      );
                    });
                  }}
                />
              </ul>
            </Flex>

            <Flex
              colLg={3}
              colMd={2}
              type="nav"
              className={classNames(
                styles.primaryFooterMenuWrapper,
                'order-0',
                'order-md-1',
                'mb-3'
              )}
            >
              <div className={styles.primaryFooterSocialWrapper}>
                <SocialMenu className={styles.primaryFooterSocial} />
              </div>
            </Flex>
          </Row>
        </Container>
      </section>

      <section className={styles.primaryFooterBottom}>
        <Container fluid className={classNames(styles.primaryFooterContainer)}>
          <Row>
            <Flex colLg={6} className={'display-flex'}>
              <FooterMenu className={styles.primaryFooterMenu} />
            </Flex>

            <Flex colLg={6} className="ml-auto align-self-center">
              <Heading className={styles.primaryFooterHeading6} priority={6}>
                {company}
              </Heading>
            </Flex>
          </Row>
        </Container>
      </section>
    </footer>
  );
};

export default PrimaryFooter;

const projectQuery = graphql`
  {
    allWordpressWpProjects(
      filter: { project_services: { elemMatch: { slug: { eq: "featured" } } } }
      limit: 5
    ) {
      edges {
        node {
          id
          wordpress_id
          title
          slug
        }
      }
    }
  }
`;
