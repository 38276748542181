import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import Svg from 'src/components/atoms/Svg/Svg';
import { TOnClick, TTheme } from 'src/utils/types';
import styles from './Brand.module.scss';
import SiteInfo from '../../atoms/SiteInfo/SiteInfo';

type LogoType = 'logo' | 'symbol' | 'long' | 'social';

interface IBrandProps {
  className?: string;
  left?: boolean;
  center?: boolean;
  right?: boolean;
  type?: LogoType;
  theme?: TTheme;
  onClick?: TOnClick;
}

const Brand: FunctionComponent<Partial<IBrandProps>> = (
  {
    className,
    type = 'logo',
    left = true,
    right,
    theme = 'tint-omega',
    center,
    ...props
  },
) => {
  let logo;

  /**
   * Switch statement to determine what brand logo is used
   */
  switch (type) {
    case 'symbol':
    case 'social':
    case 'long':
      logo = `logo-${type}`;
      break;
    default:
      logo = 'logo';
  }

  return (
    <div
      data-test="component-brand"
      className={classNames([
        styles.brand,
        {
          [styles['brand--left']]: left && !center && !right,
          [styles['brand--right']]: right,
          [styles['brand--center']]: center,
          [styles[`brand--${theme}`]]: theme,
        },
        className,
      ])}
      {...props}
    >
      <div data-test={logo} className={classNames(styles.brand__wrapper)}>
        <Svg
          name={logo}
          className={classNames([
            `${styles.brand__logo}`,
            `${styles.brand__icon}`,
          ])}
        />
        <SiteInfo/>
      </div>
    </div>
  );
};

export default Brand;
