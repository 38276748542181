import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import Container from 'src/components/grid/Container/Container';
import Flex from 'src/components/grid/Flex/Flex';
import Row from 'src/components/grid/Row/Row';
import PrimaryMenu from 'src/components/shared/PrimaryMenu/PrimaryMenu';
import withScrolling, {
  IWithScrollingInjectedProps,
} from 'src/hoc/withScrolling';
import { TOnClick } from 'src/utils/types';
import styles from './PrimaryHeader.module.scss';
// import Brand from '../../molecules/Brand/Brand';
import Logo from '../../../assets/svg/logo-long.svg';
import Hamburger from '../../atoms/Hamburger/Hamburger';

interface IPrimaryHeaderProps extends IWithScrollingInjectedProps {
  className: string;
  isMenuOpen: boolean;
  toggleMenu: TOnClick;
}

export class PrimaryHeader extends PureComponent<Partial<IPrimaryHeaderProps>> {
  render() {
    const {
      isMenuOpen,
      toggleMenu,
      isScrollingDown,
      isScrollingUp,
    } = this.props;

    return (
      <header
        data-test="component-primary-header"
        className={classNames(styles['primary-header'], {
          [styles.primaryHeaderIsScrollingUp]: isScrollingUp,
          [styles.primaryHeaderIsScrollingDown]: isScrollingDown,
        })}
      >
        <Container fluid>
          <Row>
            <Flex className={styles.primaryHeaderFlex}>
              <div className={styles['primary-header__wrapper']}>
                <Link to="/">
                  <Logo
                    className={classNames(
                      styles['primary-header__brand'],
                      {
                        [styles['primary-header__brand--is-scrolling']]: isScrollingUp || isScrollingDown,
                      },
                    )}
                  />
                </Link>

                <PrimaryMenu className={styles.primaryHeaderMenu}/>

                <Hamburger
                  className={classNames(styles['primary-header__hamburger'], {
                    [styles.primaryHeaderHamburgerIsActive]: isMenuOpen,
                  })}
                  descriptor="Menu"
                  isMenuOpen={isMenuOpen}
                  onClick={toggleMenu}
                  reverse
                />
              </div>
            </Flex>
          </Row>
        </Container>
      </header>
    );
  }
}

export default withScrolling(PrimaryHeader);
