import React, { FunctionComponent } from 'react';

import classNames from 'classnames';
import { graphql, StaticQuery } from 'gatsby';

import styles from './SiteInfo.module.scss';
import { Wordpress__Site_MetadataConnection } from 'src/utils/types/graphql';

interface SiteInfoInterface {
  className?: string;
}

interface PureSiteInfoInterface extends SiteInfoInterface {
  allWordpressSiteMetadata: Wordpress__Site_MetadataConnection;
}

export const PureSiteInfo: FunctionComponent<PureSiteInfoInterface> = ({
  allWordpressSiteMetadata,
  className,
}) => (
  <div
    data-test="component-site-info"
    className={classNames([className, styles['site-info']])}
  >
    <span className={styles['site-info__heading']}>
      {allWordpressSiteMetadata.edges[0].node.name}
    </span>
    <span className={styles['site-info__slogan']}>
      {allWordpressSiteMetadata.edges[0].node.description}
    </span>
  </div>
);

const SiteInfo: FunctionComponent<SiteInfoInterface> = ({ className }) => (
  <StaticQuery
    data-test="component-site-info-query"
    query={graphql`
      {
        allWordpressSiteMetadata {
          edges {
            node {
              id
              name
              description
            }
          }
        }
      }
    `}
    render={props => <PureSiteInfo {...props} className={className} />}
  />
);

export default SiteInfo;
