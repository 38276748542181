import { Link } from 'gatsby';
import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import Hamburger from 'src/components/atoms/Hamburger/Hamburger';
import Brand from 'src/components/molecules/Brand/Brand';
import SocialMenu from 'src/components/molecules/SocialMenu/SocialMenu';
import withScrolling, {
  IWithScrollingInjectedProps,
} from 'src/hoc/withScrolling';
import { TOnClick } from 'src/utils/types';

import styles from './OffsetMenu.module.scss';

interface IOffsetMenu extends IWithScrollingInjectedProps {
  className: string;
  isMenuOpen: boolean;
  toggleMenu: TOnClick;
}

export const OffsetMenu: FunctionComponent<Partial<IOffsetMenu>> = (
  {
    isMenuOpen,
    toggleMenu,
    isScrollingDown,
    isScrollingUp,
  },
) => {
  return (
    <section
      data-test="component-offset-menu"
      className={classNames(styles.offsetMenu, {
        [styles.offsetMenuIsActive]: isMenuOpen,
        [styles.offsetMenuIsScrollingDown]: isScrollingDown,
        [styles.offsetMenuIsScrollingUp]: isScrollingUp,
      })}
    >
      <div onClick={toggleMenu}>
        <Link to={'/'}>
          <Brand left type="symbol" className={styles.offsetMenuBrand}/>
        </Link>
      </div>

      <Hamburger
        className={classNames(styles.offsetMenuHamburger)}
        isMenuOpen={isMenuOpen}
        onClick={toggleMenu}
      />

      <SocialMenu className={styles.offsetMenuSocial}/>
    </section>
  );
};

OffsetMenu.displayName = 'OffsetMenu';

export default withScrolling(OffsetMenu);
