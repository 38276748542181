import React, { FunctionComponent } from 'react';

import classNames from 'classnames';
import { Link } from 'gatsby';
import styles from './MenuItem.module.scss';
import { sanitizeUrl } from 'src/utils';
import { TOnClick } from 'src/utils/types';

interface MenuItemInterface {
  item: IMenuItemItem;
  activeClassName?: string;
  className?: string;
  onClick?: TOnClick;
}

export interface IMenuItemItem {
  title: string;
  object_slug: string;
  url: string;
  classes?: string;
  attr?: string;
  order?: number;
  description?: string;
  wordpress_id: string | number;
  id?: string | number;
}

export const MenuItem: FunctionComponent<MenuItemInterface> = ({
  item,
  className,
  children,
  activeClassName,
  ...props
}) => {
  const { attr } = item;
  let link = item.url;
  let firstChar;
  let el;

  /**
   * Based on the item.attr we will decide what menu item to pass
   */
  switch (attr) {
    case 'divider':
      el = null;
      break;
    case 'anchor':
      el = (
        <a
          className={classNames(styles['menu-item__link'])}
          key={item.order}
          href={item.url}
          rel="noreferrer nofollow"
        >
          {children || item.title || 'No title'}
        </a>
      );
      break;
    default:
      link = sanitizeUrl(link);
      firstChar = link.charAt(0);

      if (firstChar !== '/') {
        link = `/${link}`;
      }

      el = (
        <Link
          className={classNames(styles['menu-item__link'])}
          to={`${link}`}
          key={item.order}
          activeClassName={activeClassName}
        >
          {children || item.title || 'No title'}
        </Link>
      );
      break;
  }

  return (
    <li
      data-test="component-menu-item"
      className={classNames(className, styles['menu-item'])}
      {...props}
    >
      {el}
    </li>
  );
};

export default MenuItem;
