import { Link } from 'gatsby';
import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { TTheme } from 'src/utils/types';

import styles from './TagLabel.module.scss';

interface ITagProps {
  className?: string;
  link?: string;
  theme: TTheme;
  text: string;
}

const TagLabel: FunctionComponent<ITagProps> = ({
  text,
  theme = 'brand',
  link,
  className,
}) => {
  const classes: string = classNames(
    styles.tagLabel,
    className,
    `theme--${theme}`,
    {
      [styles.tagLabelLink]: link,
    }
  );
  return (
    <>
      {link ? (
        <Link to={link} className={classes}>
          {text}
        </Link>
      ) : (
        <span className={classes}>{text}</span>
      )}
    </>
  );
};

export default TagLabel;
