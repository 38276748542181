import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import Heading from 'src/components/shared/Heading/Heading';

import styles from './FormError.module.scss';

interface IFormSuccessProps {
  className?: string;
}

const FormError: FunctionComponent<IFormSuccessProps> = (
  {
    className,
  }
) => {
  return (
    <div className={classNames(styles.formError, className)}>
      <Heading
        priority={3}
        innerHTML={false}
        className={classNames('mt-2', styles.formErrorHeading)}
      >
        Sorry your messaged failed to send.
        <br/>
        Please try again later.
      </Heading>
    </div>
  );
};

export default FormError;
