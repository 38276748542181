import React, { FunctionComponent, memo } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import classNames from 'classnames';
import AnchorLink from 'src/components/atoms/AnchorLink/AnchorLink';
import Hamburger from 'src/components/atoms/Hamburger/Hamburger';
import Button from 'src/components/molecules/Buttons/Button';
import ProjectCard from 'src/components/molecules/ProjectCard/ProjectCard';
import Heading from 'src/components/shared/Heading/Heading';
import { sanitizeEncodedChar } from 'src/utils';
import { TOnClick } from 'src/utils/types';

import styles from './MobileMenu.module.scss';
import MenuItem, { IMenuItemItem } from '../../atoms/MenuItem/MenuItem';
import { Wordpress__Wp_Api_Menus_Menus_ItemsConnection } from 'src/utils/types/graphql';

interface IPureMobileMenuProps extends IMobileMenuProps {
  allWordpressWpApiMenusMenusItems: Partial<Wordpress__Wp_Api_Menus_Menus_ItemsConnection>;
  allWordpressWpProjects: any;
}

interface IMobileMenuProps {
  className?: string;
  isMenuOpen: boolean;
  toggleMenu: TOnClick;
}

export const PureMobileMenu: FunctionComponent<Partial<IPureMobileMenuProps>> = memo(
  (
    {
      className,
      isMenuOpen,
      toggleMenu,
      allWordpressWpApiMenusMenusItems = {},
      allWordpressWpProjects = {},
    }
  ) => {
    const { edges: MenuArray = [] } = allWordpressWpApiMenusMenusItems;
    const { edges: ProjectsArray = [] } = allWordpressWpProjects;
    let menuItems;

    if (typeof window !== 'undefined') {
      menuItems = document.querySelectorAll('.mobile-menu__item');
    }

    const delay = 150;
    const step = 100;
    let locked = false;

    const homeItem: IMenuItemItem = {
      wordpress_id: 100000,
      order: 100000,
      title: 'Home',
      object_slug: '/',
      url: '/',
      classes: '',
      attr: '',
      description: 'Back to the start...',
    };

    const contactItem: IMenuItemItem = {
      wordpress_id: 100001,
      order: 100001,
      title: 'Contact',
      object_slug: '/contact',
      url: '/contact',
      classes: '',
      attr: '',
      description: 'Let\'s start something awesome...',
    };

    if (isMenuOpen && !locked && menuItems) {
      locked = true;

      menuItems.forEach(
        (menuItem: any, menuItemIndex: number) => {
          setTimeout(
            () => {
              menuItem.classList.add(styles.mobileMenuItemIsMoving);
            },
            (
              (step * menuItemIndex) + (delay * 2)
            )
          );
        });

      setTimeout(
        () => {
          locked = false;
        },
        (
          step * menuItems.length
        )
      );

    } else if (!isMenuOpen && !locked && menuItems) {
      const reversed = Array.from(menuItems).reverse();

      reversed.forEach((item: any, reversedMenuItemIndex: number) => {
        setTimeout(
          () => {
            item.classList.remove(styles.mobileMenuItemIsMoving);
          },
          (step * reversedMenuItemIndex)
        );
      });

      setTimeout(
        () => {
          locked = false;
        },
        (
          (step * menuItems.length) + delay
        )
      );
    }

    return (
      <section
        data-test="component-mobile-menu"
        className={classNames(styles.mobileMenu, className, {
          [styles.mobileMenuIsActive]: isMenuOpen,
        })}
      >
        <Hamburger
          className={classNames(styles['mobile-menu__hamburger'], {
            [styles['mobile-menu__hamburger--is-active']]: isMenuOpen,
          })}
          descriptor="Menu"
          isMenuOpen={isMenuOpen}
          onClick={toggleMenu}
          reverse
          alt
        />

        <div className={classNames(styles.mobileMenuLeft)}>
          <nav className={classNames(styles.mobileMenuNav)}>
            <ul className={styles['mobile-menu__list']}>
              <MenuItem
                className={
                  classNames(
                    styles['mobile-menu__item'],
                    'mobile-menu__item'
                  )
                }
                item={homeItem}
                onClick={toggleMenu}
              >
                {homeItem.title}
                <span className={styles.mobileMenuDescription}>
                {sanitizeEncodedChar(homeItem.description)}
              </span>
              </MenuItem>

              <li className={styles.mobileMenuItemSpacer}/>

              {MenuArray[0].node.items.map((item, menuItemIndex: number) => (
                <React.Fragment key={menuItemIndex}>
                  <MenuItem
                    className={
                      classNames(
                        styles['mobile-menu__item'],
                        'mobile-menu__item'
                      )
                    }
                    item={item}
                    onClick={toggleMenu}
                  >
                    {item.title}
                    <span className={styles.mobileMenuDescription}>
                    {sanitizeEncodedChar(item.description)}
                  </span>
                  </MenuItem>
                  <li className={styles.mobileMenuItemSpacer}/>
                </React.Fragment>
              ))}

              <li className={styles.mobileMenuItemSpacer}/>

              <MenuItem
                className={
                  classNames(
                    styles['mobile-menu__item'],
                    'mobile-menu__item'
                  )
                }
                item={contactItem}
                onClick={toggleMenu}
              >
                {contactItem.title}
                <span className={styles.mobileMenuDescription}>
                {sanitizeEncodedChar(contactItem.description)}
              </span>
              </MenuItem>
            </ul>
          </nav>

          <div className={styles.mobileMenuBottomBar} onClick={toggleMenu}>
            <Button
              className={styles.mobileMenuBottomBarButton}
              link={`/contact`}
              caps
              theme={'tint-psi'}
              icon={{ weight: 'fas', name: 'phone' }}
            >
              Call Today
            </Button>
          </div>
        </div>

        <div className={classNames(styles.mobileMenuRight, 'text-center')}>
          <Heading
            priority={3}
            className={classNames(styles.mobileMenuHeading, 'text-center')}
          >
            Recent Projects
          </Heading>

          {ProjectsArray.map((edge: any, projectIndex: number) => {
            const { node: project } = edge;
            return (
              <ProjectCard
                key={projectIndex}
                onClick={toggleMenu}
                project={project}
                showServices={false}
                shadow={true}
                className={classNames('mb-4', styles.mobileMenuProjectCard)}
              />
            );
          })}

          <AnchorLink onClick={toggleMenu} link={'/our-work'}>
            View all our work
          </AnchorLink>
        </div>
      </section>
    );
  });

export const MobileMenu: FunctionComponent<Partial<IMobileMenuProps>> = memo((
                                                                               {
                                                                                 className,
                                                                                 isMenuOpen,
                                                                                 toggleMenu,
                                                                               }
                                                                             ) => (
  <StaticQuery
    data-test="component-mobile-menu-query"
    query={query}
    render={props => {
      const { allWordpressWpProjects } = props;
      return (
        <PureMobileMenu
          {...props}
          {...allWordpressWpProjects}
          toggleMenu={toggleMenu}
          isMenuOpen={isMenuOpen}
          className={className}
        />
      );
    }}
  />
));

export default MobileMenu;

const query = graphql`
  {
    allWordpressWpApiMenusMenusItems(filter: { name: { eq: "Mobile Menu" } }) {
      edges {
        node {
          wordpress_id
          name
          items {
            wordpress_id
            order
            title
            object_slug
            url
            classes
            attr
            description
          }
        }
      }
    }
    allWordpressWpProjects(
      filter: { project_services: { elemMatch: { slug: { eq: "featured" } } } }
    ) {
      edges {
        node {
          id
          wordpress_id
          title
          slug
          featured_media {
            ...media
          }
          acf {
            logo {
              ...media
            }
          }
        }
      }
    }
  }
  
  fragment media on wordpress__wp_media {
    id
    wordpress_id
    alt_text
    source_url
    media_type
    media_details {
      width
      height
    }
  }
`;
