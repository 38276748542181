import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import PageTransition from 'gatsby-plugin-page-transitions';
import CookiePolicy from 'src/components/organisms/CookiePolicy/CookiePolicy';
import Meta from 'src/components/shared/Meta/Meta';
import MobileMenu from 'src/components/shared/MobileMenu/MobileMenu';
import OffsetMenu from 'src/components/shared/OffsetMenu/OffsetMenu';
import PrimaryFooter from 'src/components/shared/PrimaryFooter/PrimaryFooter';
import PrimaryHeader from 'src/components/shared/PrimaryHeader/PrimaryHeader';
import { removeEncodedChar, sanitizePathName } from 'src/utils';
import * as coreActions from '../../store/core/actions';
import { IAppState, IMenuOpen, IStateDispatch } from 'src/utils/interfaces';
import { TOnClick } from 'src/utils/types';
import styles from '../Layouts.module.scss';

interface ICoreLayout {
  title?: string;
  description?: string;
  className?: string;
  isMenuOpen?: boolean;
  toggleMenu?: TOnClick;
  location?: Location;

  onSetMenuState(state: boolean): AnyAction;
}

export class UnconnectedCoreLayout extends PureComponent<ICoreLayout> {
  static defaultProps = {
    title: process.env.GATSBY_META_TITLE,
    description: process.env.GATSBY_META_DESCRIPTION,
  };

  constructor(props: ICoreLayout) {
    super(props);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  /**
   * Toggle the menu state
   * @param e
   */
  toggleMenu(e: React.MouseEvent): void {
    const { isMenuOpen, onSetMenuState } = this.props;
    onSetMenuState(!isMenuOpen);

    e.preventDefault();
  }

  render() {
    const {
      children,
      title,
      description,
      className,
      isMenuOpen,
      location,
    } = this.props;

    return (
      <>
        <Meta title={title} description={description}/>

        <MobileMenu isMenuOpen={isMenuOpen} toggleMenu={this.toggleMenu}/>

        <OffsetMenu isMenuOpen={isMenuOpen} toggleMenu={this.toggleMenu}/>

        <CookiePolicy/>

        <div
          className={classNames(
            removeEncodedChar(
              location && location.pathname === '/'
                ? styles['page-home']
                : location &&
                styles[`page${sanitizePathName(location.pathname)}`]
            ),
            styles.page,
            {
              ['menu-is-active']: isMenuOpen,
            }
          )}
        >
          <PrimaryHeader isMenuOpen={isMenuOpen} toggleMenu={this.toggleMenu}/>

          <PageTransition transitionTime={200}>
            <main
              id="js-main"
              data-test="core-layout-main"
              className={classNames([styles.primaryMain, className])}
            >
              {children}
            </main>

            <PrimaryFooter/>
          </PageTransition>
        </div>
      </>
    );
  }
}

export default connect(
  ({ core: { isMenuOpen } }: IMenuOpen) => ({
    isMenuOpen,
  }),
  (dispatch: ThunkDispatch<IAppState, IStateDispatch, AnyAction>) => ({
    onSetMenuState: (state: boolean) =>
      dispatch(coreActions.setMenuState(state)),
  })
)(UnconnectedCoreLayout);
