import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import Button from 'src/components/molecules/Buttons/Button';
import Heading from 'src/components/shared/Heading/Heading';
import { TTheme } from 'src/utils/types';

import styles from './FormSuccess.module.scss';

interface IFormSuccessProps {
  className?: string;
  theme: TTheme;
  resetState: () => void;
}

const FormSuccess: FunctionComponent<IFormSuccessProps> = ({
  className,
  resetState,
  theme,
}) => {
  return (
    <div className={classNames(styles.formSuccess, className)}>
      <Heading
        priority={3}
        innerHTML={false}
        className={classNames('mt-2', styles.formSuccessHeading)}
      >
        Thanks for getting in touch.
        <br />A member of the team will get back to you shortly
      </Heading>

      <Button
        theme={theme}
        className="mt-1"
        outline
        caps
        behaviour={'action'}
        onClick={resetState}
      >
        Send another message
      </Button>
    </div>
  );
};

export default FormSuccess;
