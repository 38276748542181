import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';
import { Link } from 'gatsby';
import { IconName } from '@fortawesome/fontawesome-common-types';
import classNames from 'classnames';
import {
  TLinkBehaviour,
  TLinkType,
  TOnClick,
  TSize,
  TTheme,
} from 'src/utils/types';
import styles from './Button.module.scss';

export interface IButtonProps {
  theme: TTheme;
  size: TSize;
  behaviour: TLinkBehaviour;
  caps: boolean;
  className: string;
  type: TLinkType;
  link: string;
  onClick: TOnClick;
  outline: boolean;
  tabIndex: number;
  icon: {
    weight: 'fab' | 'far' | 'fal' | 'fas' | 'fad';
    name: IconName;
  };
}

const Button: FunctionComponent<Partial<IButtonProps>> = ({
  children,
  behaviour = 'router',
  theme = 'tint-omega',
  link,
  size = 'md',
  caps = false,
  className,
  outline,
  type,
  onClick,
  icon,
  ...props
}) => {
  let button;
  const content = (
    <>
      <span className={styles.btnContent}>
        {children && (
          <span
            className={classNames(styles.btnText, {
              [styles.btnTextWithIcon]: icon,
            })}
          >
            {children}
          </span>
        )}
        {icon && (
          <span className={styles.btnIconWrapper}>
            <FontAwesomeIcon icon={[icon.weight, icon.name]} />
          </span>
        )}
      </span>
    </>
  );
  const defaultProps = {
    'data-test': 'component-button',
    className: classNames([
      styles.btn,
      styles[`btn--${size}`],
      {
        [styles[`btn--${theme}`]]: theme,
        [styles[`btn--outline`]]: outline,
        [styles[`btn--capitalise`]]: caps,
      },
      className,
    ]),
    onClick,
    ...props,
  };

  switch (behaviour) {
    case 'router':
    default:
      button = (
        <Link {...defaultProps} to={link}>
          {content}
        </Link>
      );
      break;

    case 'anchor':
      button = (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer nofollow"
          {...defaultProps}
        >
          {content}
        </a>
      );
      break;

    case 'action':
      button = (
        <button type={type || 'button'} {...defaultProps}>
          {content}
        </button>
      );
      break;
  }

  return <>{button}</>;
};

export default Button;
