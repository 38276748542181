import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import Logo from 'src/assets/svg/logo.svg';
import LogoSymbol from 'src/assets/svg/logo-symbol.svg';
import LogoSocial from 'src/assets/svg/logo-social.svg';
import LogoLong from 'src/assets/svg/logo-long.svg';
import styles from './Svg.module.scss';

type TSvg = 'logo' | 'symbol' | 'social';

interface ISvg {
  name: TSvg | string;
  title?: string;
  className?: string;
  center?: boolean;
}

const Svg: FunctionComponent<ISvg> = ({
  name,
  title,
  className,
  center = false,
}) => {
  let svg;
  const svgTitle = title ? title : name;
  const svgProps = {
    'data-test': 'component-svg',
    className: styles.svgIcon,
    role: 'img',
    'aria-label': svgTitle,
  };

  switch (name) {
    case 'logo':
      svg = <Logo {...svgProps} />;
      break;

    case 'logo-long':
      svg = <LogoLong {...svgProps} />;
      break;

    case 'logo-symbol':
      svg = <LogoSymbol {...svgProps} />;
      break;

    case 'logo-social':
      svg = <LogoSocial {...svgProps} />;
      break;

    default:
      svg = <>Cannot find SVG file</>;
      break;
  }

  return (
    <div
      className={classNames(styles.svg, className, {
        [styles.iconCenter]: center,
      })}
    >
      {svg}
    </div>
  );
};

export default Svg;
