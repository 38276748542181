import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './FormField.module.scss';

interface IFormField {
  className?: string;
}

const FormField: FunctionComponent<IFormField> = ({ className, children }) => {
  return (
    <div
      data-test="component-form-field"
      className={classNames([styles['form-field'], className])}
    >
      {children}
    </div>
  );
};

export default FormField;
