import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { TSize } from 'src/utils/types';

import styles from './FormLabel.module.scss';

interface IFormLabel {
  text: string;
  name: string;
  size?: TSize;
  required?: boolean;
  className?: string;
}

const FormLabel: FunctionComponent<IFormLabel> = (
  {
    text,
    name,
    size = 'md',
    className,
    required = false
  }
) => {
  return (
    <label
      id={name}
      data-test="component-form-label"
      htmlFor={name}
      aria-label={`Label for ${name}`}
      className={classNames(
        styles.formLabel,
        styles[`form-label--${size}`],
        className
      )}
    >
      {text}:{required && (<>{' '}<span className={styles.formLabelStar}>*</span></>)}
    </label>
  );
};

export default FormLabel;
