import { Link } from 'gatsby';
import React, { PureComponent } from 'react';
import { ReactCookieProps, withCookies } from 'react-cookie';
import Button from 'src/components/molecules/Buttons/Button';
import styles from './CookiePolicy.module.scss';

interface ICookiePolicyState {
  show: boolean;
}

const COOKIE = {
  name: 'PTCookiePopup',
  value: 'accepted',
  daysToExpire: 30,
};

export class CookiePolicy extends PureComponent<ReactCookieProps> {
  state: ICookiePolicyState = {
    show: false,
  };

  constructor(props: any) {
    super(props);

    this.setCookiePolicyState = this.setCookiePolicyState.bind(this);
    this.handleCookieAccept = this.handleCookieAccept.bind(this);
    this.closeCookiePolicy = this.closeCookiePolicy.bind(this);
    this.getCookies = this.getCookies.bind(this);
  }

  componentDidMount(): void {
    this.getCookies();
  }

  closeCookiePolicy(): void {
    this.setState({
      show: false,
    });
  }

  getCookies(): void {
    const { cookies } = this.props;
    const cookie = cookies.get(COOKIE.name);

    if (cookie !== undefined) {
      return this.setCookiePolicyState(false);
    }

    return this.setCookiePolicyState(true);
  }

  handleCookieAccept(): void {
    const { cookies } = this.props;

    const expires = new Date();
    expires.setTime(
      expires.getTime() + COOKIE.daysToExpire * 24 * 60 * 60 * 1000
    );
    expires.toUTCString();

    cookies.set(COOKIE.name, COOKIE.value, { path: '/', expires });
    this.setState({ show: false });
  }

  setCookiePolicyState(show: boolean) {
    this.setState({
      show,
    });
  }

  _removeCookie(): void {
    const { cookies } = this.props;
    cookies.remove(COOKIE.name);
  }

  render() {
    const { show } = this.state;

    if (show) {
      return (
        <>
          <div className={styles.cookiePolicy}>
            <Button
              tabIndex={0}
              type={'button'}
              behaviour={'action'}
              className={styles.cookiePolicyCloseButton}
              onClick={this.closeCookiePolicy}
              icon={{
                weight: 'far',
                name: 'times',
              }}
            />

            <p className={styles.cookiePolicyCopy}>
              We use cookies on our websites. You are free to manage this via
              your browser setting at any time. To learn more about how we use
              the cookies, please see our{' '}
              <Link
                className={styles.cookiePolicyCopyLink}
                to={'/privacy-policy'}
              >
                Cookie & Privacy Policy
              </Link>
            </p>

            <div className={styles.cookiePolicyButtonWrapper}>
              <Button
                type={'button'}
                behaviour={'action'}
                className={styles.cookiePolicyButton}
                onClick={this.closeCookiePolicy}
                icon={{
                  weight: 'far',
                  name: 'times',
                }}
                caps
                size={'xs'}
                theme={'tint-alpha'}
                outline
              >
                Close
              </Button>

              <Button
                type={'button'}
                behaviour={'action'}
                className={styles.cookiePolicyButton}
                onClick={this.handleCookieAccept}
                icon={{
                  weight: 'far',
                  name: 'check',
                }}
                caps
                size={'xs'}
                theme={'tint-alpha'}
              >
                Accept
              </Button>
            </div>
          </div>
        </>
      );
    }

    return null;
  }
}

export default withCookies(CookiePolicy);
