import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React, { FunctionComponent, useState } from 'react';
import classNames from 'classnames';
import LazyLoad from 'react-lazy-load';

import TagLabel from 'src/components/atoms/TagLabel/TagLabel';
import Heading from 'src/components/shared/Heading/Heading';
import { mapSlugToTheme, sanitizeEncodedChar } from 'src/utils';
import { TOnClick } from 'src/utils/types';

import styles from './ProjectCard.module.scss';

interface IProjectBlockProps {
  className?: string;
  project: any;
  shadow?: boolean;
  showServices?: boolean;
  onClick?: TOnClick;
}

interface IProjectBlockState {
  lazyOffset: number;
}

const ProjectCard: FunctionComponent<IProjectBlockProps> = (
  {
    className,
    project,
    onClick,
    shadow = false,
    showServices = true,
  }
) => {
  const [state] = useState<IProjectBlockState>(
    {
      lazyOffset: 1000
    }
  );

  const {
    title,
    slug,
    acf = {},
    featured_media,
    project_services: services,
  } = project;

  const { logo } = acf;

  const headerStyles = {
    // backgroundImage: featured_media && `url(${featured_media.source_url})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  };

  const imageAttrs = {
    loading: 'lazy'
  };

  return (
    <article
      className={classNames(styles.projectCard, className, {
        [styles.projectCardWithShadow]: shadow,
      })}
      onClick={onClick}
    >
      <Link to={`/project/${slug}`} className={styles.projectCardLink}>
        <span className="sr-only">{sanitizeEncodedChar(title)}</span>
      </Link>

      <header className={styles.projectCardHeader} style={headerStyles}>
        <div className={styles.projectCardHeaderBackground}>
          {(featured_media && featured_media.media_details) && (
            <LazyLoad offset={state.lazyOffset} height={featured_media.media_details.height}>
              <img
                className={styles.projectCardHeaderBackgroundImage}
                src={featured_media.source_url}
                alt={featured_media.alt_text}
                width={featured_media.media_details.width}
                height={featured_media.media_details.height}
                {...imageAttrs}
              />
            </LazyLoad>
          )}
        </div>

        {logo && (
          <LazyLoad offset={state.lazyOffset} height={logo.media_details.height}>
            <img
              className={styles.projectCardLogo}
              src={logo.source_url}
              alt={logo.alt_text}
              width={logo.media_details.width}
              height={logo.media_details.height}
              {...imageAttrs}
            />
          </LazyLoad>
        )}
      </header>

      <div className={styles.projectCardContent}>
        <Heading
          className={styles.projectCardHeading}
          innerHTML={false}
          priority={4}
        >
          <span>{sanitizeEncodedChar(title)}</span>
        </Heading>

        {services && showServices && (
          <div className={styles.projectCardServicesWrapper}>
            <p className={styles.projectCardCopy}>
              Service{services.length === 1 ? '' : 's'} carried out:
            </p>

            {services.map((service: any) => {
              const { name, id, slug: serviceSlug } = service;

              return (
                <TagLabel
                  key={id}
                  className={styles.projectCardServices}
                  theme={mapSlugToTheme(serviceSlug)}
                  text={name}
                />
              );
            })}
          </div>
        )}
      </div>
      <p className={styles.projectCardButton}>
        <FontAwesomeIcon
          className={styles.projectCardButtonIcon}
          icon={['far', 'link']}
        />
        <span className={styles.projectCardButtonText}>
          Learn about what we did...
        </span>
      </p>
    </article>
  );
};

export default ProjectCard;
