import React, { FunctionComponent } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styles from './FooterMenu.module.scss';
import MenuItem from '../../atoms/MenuItem/MenuItem';
import { Wordpress__Wp_Api_Menus_Menus_ItemsConnection } from 'src/utils/types/graphql';

interface PureFooterMenuInterface extends FooterMenuInterface {
  allWordpressWpApiMenusMenusItems: Wordpress__Wp_Api_Menus_Menus_ItemsConnection;
}

interface FooterMenuInterface {
  className?: string;
}

export const PureFooterMenu: FunctionComponent<PureFooterMenuInterface> = ({
  className,
  allWordpressWpApiMenusMenusItems,
}) => (
  <nav
    data-test="component-footer-menu"
    className={`${styles['footer-menu']} ${className}`}
  >
    <ul className={styles['footer-menu__list']}>
      {allWordpressWpApiMenusMenusItems.edges[0].node.items.map(item => (
        <MenuItem
          className={styles['footer-menu__item']}
          key={item.order}
          item={item}
        />
      ))}
    </ul>
  </nav>
);

const FooterMenu: FunctionComponent<FooterMenuInterface> = ({ className }) => (
  <StaticQuery
    data-test="component-footer-menu-query"
    query={menuQuery}
    render={props => <PureFooterMenu {...props} className={className} />}
  />
);

export default FooterMenu;

const menuQuery = graphql`
  {
    allWordpressWpApiMenusMenusItems(filter: { name: { eq: "Footer Menu" } }) {
      edges {
        node {
          id
          name
          items {
            wordpress_id
            order
            title
            object_slug
            url
            classes
            attr
          }
        }
      }
    }
  }
`;
