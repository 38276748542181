import React, { FormEvent, FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './SelectField.module.scss';
import FormLabel from '../FormLabel/FormLabel';

export interface ISelectField {
  name: string;
  options: ISelectOptions[];
  label?: string;
  placeholder?: string;
  className?: string;
  required?: boolean;
  onChange?: (e: FormEvent<any>) => void;
}

export interface ISelectOptions {
  name: string;
  value: string;
}

const SelectField: FunctionComponent<ISelectField> = (
  {
    name,
    placeholder,
    options,
    label,
    className,
    required = true,
    ...props
  }
) => {
  return (
    <>
      {label && <FormLabel text={label} name={name} required={required}/>}
      <div
        data-test="component-select-field"
        className={classNames(styles['select-field'], className)}
      >
        <select
          className={styles['select-field__select']}
          name={name}
          placeholder={placeholder}
          required={required}
          defaultValue=""
          {...props}
        >
          <option disabled value="">
            Please select an option
          </option>
          {options.map((option, index) => {
            return (
              <option key={index} value={option.value}>
                {option.name}
              </option>
            );
          })}
        </select>
      </div>
    </>
  );
};

export default SelectField;
