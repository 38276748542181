import React, { FunctionComponent } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import classNames from 'classnames';

import styles from './PrimaryMenu.module.scss';
import MenuItem, { IMenuItemItem } from '../../atoms/MenuItem/MenuItem';
import { Wordpress__Wp_Api_Menus_Menus_ItemsConnection } from 'src/utils/types/graphql';

interface IPurePrimaryMenu extends IPrimaryMenu {
  allWordpressWpApiMenusMenusItems: Partial<
    Wordpress__Wp_Api_Menus_Menus_ItemsConnection
  >;
}

interface IPrimaryMenu {
  className?: string;
}

const contactItem: IMenuItemItem = {
  wordpress_id: 100001,
  order: 100001,
  title: 'Contact',
  object_slug: '/contact',
  url: '/contact',
  classes: '',
  attr: '',
};

export const PurePrimaryMenu: FunctionComponent<IPurePrimaryMenu> = ({
  className,
  allWordpressWpApiMenusMenusItems,
}) => {
  return (
    <>
      <nav
        data-test="component-primary-menu"
        className={classNames(styles['primary-menu'], className)}
      >
        <ul className={styles['primary-menu__list']}>
          {allWordpressWpApiMenusMenusItems.edges[0].node.items.map(item => (
            <MenuItem
              className={styles['primary-menu__item']}
              key={item.wordpress_id}
              item={item}
              activeClassName={styles.primaryMenuItemActive}
            >
              {item.title}
            </MenuItem>
          ))}

          <MenuItem
            className={styles['primary-menu__item']}
            activeClassName={styles.primaryMenuItemActive}
            item={contactItem}
          >
            {contactItem.title}
          </MenuItem>
        </ul>
      </nav>
    </>
  );
};

export const PrimaryMenu: FunctionComponent<IPrimaryMenu> = ({ className }) => (
  <StaticQuery
    data-test="component-primary-menu-query"
    query={menuQuery}
    render={props => <PurePrimaryMenu {...props} className={className} />}
  />
);

export default PrimaryMenu;

const menuQuery = graphql`
  {
    allWordpressWpApiMenusMenusItems(filter: { name: { eq: "Primary Menu" } }) {
      edges {
        node {
          wordpress_id
          name
          items {
            wordpress_id
            order
            title
            object_slug
            url
            classes
            attr
          }
        }
      }
    }
  }
`;
