import React, { FunctionComponent } from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { sanitizeEncodedChar } from 'src/utils';

interface IMetaProps {
  description: string;
  lang?: string;
  meta?: [];
  keywords?: string[];
  title: string;
  ogType?: string;
  data?: {
    site: {
      siteMetadata: {
        title: string;
        description: string;
        author?: string;
      };
    };
  };
}

export const PureMeta: FunctionComponent<IMetaProps> = (
  {
    data,
    description,
    lang = 'en',
    meta = [],
    keywords = [],
    title,
    ogType = 'website',
  },
) => {
  const metaDescription = description || data.site.siteMetadata.description;
  const pageTitle = sanitizeEncodedChar(title);

  return (
    <Helmet
      data-test="seo-helmet"
      htmlAttributes={{
        lang,
      }}
      title={pageTitle}
      titleTemplate={`%s | ${data.site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: ogType,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: data.site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
              name: `keywords`,
              content: keywords.join(`, `),
            }
            : [],
        )
        .concat(meta)}
    />
  );
};

const Meta: FunctionComponent<IMetaProps> = props => (
  <StaticQuery
    query={detailsQuery}
    render={data => (
      <PureMeta data-test="seo-helmet-query" data={data} {...props} />
    )}
  />
);

export default Meta;

const detailsQuery = graphql`
  query DefaultMetaQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;
