import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import SocialItem from '../../atoms/SocialItem/SocialItem';

import styles from './SocialShareMenu.module.scss';

interface ISocialMenuProps {
  className: string;
  location: Location;
}

export const SocialShareMenu: FunctionComponent<Partial<ISocialMenuProps>> = ({
  className,
  location,
}) => {
  let popUp: any;

  const openFbShare = () => {
    popUp = window.open(
      `${process.env.GATSBY_PROTOCOL}://www.facebook.com/sharer.php?u=${location.href}`,
      'popupwindow',
      'scrollbars=yes,width=800,height=400'
    );
    popUp.focus();
    return false;
  };

  const openTwShare = () => {
    popUp = popUp = window.open(
      `${process.env.GATSBY_PROTOCOL}://twitter.com/intent/tweet?text=Check this out from 
      ${process.env.GATSBY_META_TITLE} - ${location.href}`,
      'popupwindow',
      'scrollbars=yes,width=400,height=400'
    );
    popUp.focus();
    return false;
  };

  return (
    <nav
      data-test="component-social-share-menu"
      className={classNames(styles['social-share-menu'], className)}
    >
      <ul className={styles['social-share-menu__list']}>
        <li
          className={classNames(
            styles.socialShareMenuItem,
            styles.socialShareMenuItemHeading
          )}
        >
          Share this page:
        </li>

        <SocialItem
          className={styles['social-share-menu__item']}
          name="facebook"
          onClick={openFbShare}
        />

        <SocialItem
          className={styles['social-share-menu__item']}
          name="twitter-square"
          onClick={openTwShare}
        />
      </ul>
    </nav>
  );
};

export default SocialShareMenu;
