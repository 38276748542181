import { Link } from 'gatsby';
import React, { FunctionComponent } from 'react';
import { TLinkBehaviour, TOnClick } from 'src/utils/types';
import classNames from 'classnames';

import styles from './AnchorLink.module.scss';

interface IAnchorLinkProps {
  className: string;
  link: string;
  behaviour: TLinkBehaviour;
  onClick: TOnClick;
}

const AnchorLink: FunctionComponent<Partial<IAnchorLinkProps>> = ({
  children,
  className,
  behaviour = 'router',
  link,
  onClick,
}) => {
  let Tag;

  const defaultProps = {
    'data-test': 'component-anchor-link',
    className: classNames(styles.anchorLink, className),
    onClick,
  };

  switch (behaviour) {
    case 'anchor':
      Tag = (
        <a {...defaultProps} href={link}>
          {children}
        </a>
      );
      break;
    default:
    case 'router':
      Tag = (
        <Link {...defaultProps} to={link}>
          {children}
        </Link>
      );
      break;
  }

  return Tag;
};

export default AnchorLink;
