import React, { FormEvent, FunctionComponent, useState } from 'react';
import classNames from 'classnames';
import serialize from 'form-serialize';
import Loading from 'src/components/atoms/Loading/Loading';
import FormSuccess from 'src/components/molecules/FormSuccess/FormSuccess';
import { IFormButton, IFormGroup } from 'src/utils/interfaces';
import { TOnSubmit, TTheme } from 'src/utils/types';

import InputField from '../../atoms/InputField/InputField';
import SelectField from '../../atoms/SelectField/SelectField';
import FormField from '../FormField/FormField';
import Button from '../Buttons/Button';
import Row from '../../grid/Row/Row';
import Flex from '../../grid/Flex/Flex';

import styles from './FormBase.module.scss';
import { slugify } from 'src/utils';
import FormError from 'src/components/molecules/FormError/FormError';

export interface IFormBaseProps {
  className?: string;
  encType?: string;
  formName: string;
  successButtonTheme?: TTheme;
  centerButtons?: boolean;
  loadingTheme?: TTheme;
  buttons: Partial<IFormButton>[];
  formGroups: IFormGroup[];
}

interface IFormBaseState {
  isLoading: boolean;
  hasError: boolean;
  isSubmitted: boolean;
  error: any;
  errorMessage: string;
}

const FormBase: FunctionComponent<IFormBaseProps> = (
  {
    className,
    formName,
    buttons,
    formGroups,
    loadingTheme = 'tint-alpha',
    successButtonTheme = 'tint-alpha',
    centerButtons = true,
    encType = 'multipart/form-data',
  }
) => {
  const [state, setState] = useState<IFormBaseState & any>(
    {
      isLoading: false,
      hasError: false,
      isSubmitted: false,
      error: {},
      errorMessage: '',
    }
  );

  const handleInputChange = (e: FormEvent<any>): void => {
    setState(
      {
        ...state,
        [e.currentTarget.name]: e.currentTarget.value,
      }
    );
  };

  const resetState = (): void => {
    setState(
      {
        ...state,
        isLoading: false,
        hasError: false,
        isSubmitted: false,
        errorMessage: '',
      }
    );
  };

  const onSubmit: TOnSubmit = e => {
    const form: any = e.currentTarget;
    const body = serialize(form);

    setState({ ...state, isLoading: true });

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body,
    })
      .then((response): void | Error => {
        console.log('Form sent response', response);
        if (response.status === 200) {
          form.reset();

          return setState(
            {
              ...state,
              isLoading: false,
              isSubmitted: true,
              hasError: false,
            }
          );
        }

        return setState(
          {
            ...state,
            isLoading: false,
            isSubmitted: false,
            hasError: true,
            errorMessage: 'Form failed to send. Please try again',
          }
        );
      })
      .catch((error): void => {
        console.error('Form error response', error);
        return setState(
          {
            ...state,
            isLoading: false,
            isSubmitted: false,
            hasError: true,
            error,
            errorMessage: 'Form failed to send. Please try again',
          }
        );
      });

    // Prevent Default behaviour
    e.preventDefault();
  };

  return (
    <>
      {state.isLoading && (
        <Loading theme={loadingTheme}/>
      )}

      {state.isSubmitted && (
        <FormSuccess
          className={styles.formBaseSuccess}
          theme={successButtonTheme}
          resetState={resetState}
        />
      )}

      {(state.hasError && !state.isSubmitted) && (
        <FormError
          className={styles.formBaseSuccess}
        />
      )}

      {(!state.isSubmitted && !state.hasError) && (
        <form
          id={slugify(formName)}
          data-test="component-form-base"
          className={classNames(styles['form-base'], className)}
          encType={encType}
          method="post"
          data-netlify="true"
          netlify-honeypot="bot-field"
          onSubmit={onSubmit}
        >
          <input
            type="hidden"
            name={slugify(formName)}
            value={slugify(formName)}
          />

          <p className="visibility-hidden display-none">
            <label>
              Don’t fill this out:{' '}
              <input name="bot-field" onChange={handleInputChange}/>
            </label>
          </p>

          {formGroups.map(
            ({ fieldSet: { legend, description, fields } }, groupIndex) => (
              <div key={groupIndex} className={styles['form-base__group']}>
                {legend && (
                  <legend className={styles['form-base__legend']}>
                    {legend}
                  </legend>
                )}
                {description && (
                  <p className={styles['form-base__description']}>
                    {description}
                  </p>
                )}
                <Row className={styles['form-base__row']}>
                  {fields.map(
                    (
                      {
                        type,
                        input: {
                          name,
                          type: inputType,
                          placeholder,
                          options,
                          className: FieldClassName,
                          ...field
                        },
                        columns,
                      },
                      index
                    ) => (
                      <Flex
                        key={index}
                        colXl={columns ? columns.colXl : undefined}
                        colLg={columns ? columns.colLg : undefined}
                        colMd={columns ? columns.colMd : undefined}
                        colSm={columns ? columns.colSm : undefined}
                        colXs={columns ? columns.colXs : undefined}
                        col={columns ? columns.col || 12 : 12}
                      >
                        <FormField
                          className={classNames(
                            styles['form-base__form-field']
                          )}
                        >
                          {type === 'input' && (
                            <InputField
                              name={name}
                              type={inputType}
                              placeholder={placeholder}
                              className={classNames(
                                styles['form-base__input-field'],
                                FieldClassName
                              )}
                              onChange={handleInputChange}
                              {...field}
                            />
                          )}
                          {type === 'select' && (
                            <SelectField
                              onChange={handleInputChange}
                              name={name}
                              placeholder={placeholder}
                              className={classNames(
                                styles['form-base__select-field'],
                                FieldClassName
                              )}
                              options={options}
                              {...field}
                            />
                          )}
                        </FormField>
                      </Flex>
                    )
                  )}
                </Row>
              </div>
            )
          )}

          <Row
            className={classNames(styles['form-base__row'], {
              'justify-content-center': centerButtons,
            })}
          >
            {buttons.map(
              (
                { theme, type, className: buttonClassName, text, size = 'lg' },
                buttonIndex
              ) => (
                <Flex key={buttonIndex} auto>
                  <FormField
                    className={classNames(styles['form-base__buttons'])}
                  >
                    <Button
                      caps
                      theme={theme}
                      behaviour="action"
                      type={type}
                      className={classNames(
                        buttonClassName,
                        styles['form-base__button']
                      )}
                      size={size}
                      icon={{
                        weight: 'far',
                        name: 'angle-double-right',
                      }}
                    >
                      {text}
                    </Button>
                  </FormField>
                </Flex>
              )
            )}
          </Row>
        </form>
      )}
    </>
  );
};

export default FormBase;
